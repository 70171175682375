import Prismic from '@prismicio/client'
import config from '@tofu/apps/shop/constants/env'

const apiEndpoint = `https://${config.prismic_repo}.cdn.prismic.io/api/v2`
const accessToken = ''

const createClientOptions = (req = null, prismicAccessToken = null) => {
  const reqOption = req ? { req } : {}
  const accessTokenOption = prismicAccessToken
    ? { accessToken: prismicAccessToken }
    : {}
  return {
    ...reqOption,
    ...accessTokenOption
  }
}

// Client method to query documents from the Prismic repo
const Client = (req = null) =>
  Prismic.client(apiEndpoint, createClientOptions(req, accessToken))

const client = Client()

export default client
