import { useToast } from '@chakra-ui/toast'
import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useCart } from '@tofu/shop/hooks/use-cart'

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  addCartItem,
  createCart,
  removeCartItem,
  updateCart as updateCartService,
  updateCartItem
} from '@tofu/apps/shop/services/cart'
import {
  getDiscountErrorMsg,
  isErrorDiscountNotFound,
  isErrorDiscountForNewCustomersOnly
} from '@tofu/apps/shop/utils/error'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useBasketToast } from '@tofu/shared/hooks/use-basket-toast'
import { invalidateQueryAndMaybeShowToast } from '@tofu/shared/utils/cart'
import { logger } from '@tofu/shared/utils/sentry'
import { useFeedback } from './feedback'
import { useSession } from './session'

const BasketContext = createContext({
  isOpen: false,
  toggle: () => {}
})

const BasketProvider = ({ children }) => {
  const [isOpen, toggleBasket] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll')
    }
    return () => {
      document.body.classList.remove('no-scroll')
    }
  }, [isOpen])

  return (
    <BasketContext.Provider
      value={{
        isOpen,
        toggle: toggleBasket
      }}
    >
      {children}
    </BasketContext.Provider>
  )
}

BasketProvider.defaultProps = {
  children: null
}

BasketProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const useBasket = () => {
  const { isOpen, toggle } = useContext(BasketContext)
  const toast = useToast()
  const { data: cart = {} } = useCart()

  const feedback = useFeedback()

  const { data: sessionData, setCookies } = useSession()
  const { cartToken } = sessionData || {}
  const queryClient = useQueryClient()

  const { showToast } = useBasketToast()

  const initialiseCart = useMutation((data) => createCart(data), {
    onSuccess: ({ token }) => setCookies('session_cart_token', token)
  })

  const add = useMutation(
    ({ variantId }) => addCartItem(cartToken, variantId),
    {
      onError: (error) => {
        logger(error)
        feedback.add(
          'error',
          'We could not add your cart items. Please try again.'
        )
      },
      onSuccess: invalidateQueryAndMaybeShowToast(queryClient, cartToken)
    }
  )

  const remove = useMutation((id) => removeCartItem(id), {
    onError: (error) => {
      logger(error)
      feedback.add(
        'error',
        'We cannot remove your cart item. Please try again.'
      )
    },
    onSuccess: () => queryClient.invalidateQueries(['getCart'])
  })

  const updateQuantity = useMutation(
    ({ id, quantity }) => updateCartItem(id, quantity),
    {
      onError: () =>
        feedback.add(
          'error',
          'We could not update your cart items. Please try again.'
        ),
      onSuccess: invalidateQueryAndMaybeShowToast(queryClient, cartToken)
    }
  )

  const update = useMutation((data) => updateCartService(cartToken, data), {
    onError: (error) => {
      logger(error)

      if (isErrorDiscountNotFound(error)) {
        toast({
          duration: 3000,
          status: 'warning',
          title: 'Uh oh...',
          description: 'We cannot apply that code. Please try again.'
        })

        return
      }

      if (isErrorDiscountForNewCustomersOnly(error)) {
        toast({
          isClosable: true,
          duration: null,
          status: 'error',
          description: "Sorry, the code won't work with this order."
        })

        return
      }

      toast({
        duration: 3000,
        status: 'warning',
        title: 'Uh oh...',
        description: 'We cannot update your cart. Please try again.'
      })
    },
    onSuccess: (data, variables) => {
      if (variables?.discount_codes && data?.discount_codes?.length > 0) {
        // Render special success msg when discounts have been applied
        const description = getDiscountErrorMsg(data.discount_codes[0])

        toast({
          duration: 3000,
          status: 'success',
          title: 'Hooray!',
          description
        })
      }
      queryClient.invalidateQueries(['getCart'])
    }
  })

  const addItem = async (variantId, cb) => {
    const cartItem = cart?.items?.find(
      ({ variant_id }) => variant_id === variantId
    )

    if (cartItem) {
      // If already in cart, increment quantity by 1
      await updateQuantity.mutateAsync({
        id: cartItem.id,
        quantity: cartItem.quantity + 1,
        ...(cb && { cb })
      })
    } else {
      if (!cartToken) {
        // If no cart, create cart then add
        await initialiseCart.mutateAsync()
      }

      // POST new cart item
      await add.mutateAsync({ variantId, cb })
    }
  }

  const addItemWithToast = async (variantId) => {
    await addItem(variantId, showToast)
  }

  const removeItem = async (id, quantity) => {
    if (quantity > 1) {
      // Decrement quantity by 1
      updateQuantity.mutateAsync({
        id,
        quantity: quantity - 1
      })
    } else {
      // DELETE cart item
      remove.mutateAsync(id)
    }
  }

  const updateItemQuantity = async (id, quantity, cb) => {
    if (quantity >= 1) {
      updateQuantity.mutateAsync({
        id,
        quantity,
        ...(cb && { cb })
      })
    } else {
      remove.mutateAsync(id)
    }
  }

  const updateItemQuantityWithToast = async (id, quantity) => {
    const cartItem = cart?.items?.find(({ id }) => id === id)
    const isIncrease = cartItem?.quantity < quantity

    await updateItemQuantity(id, quantity, isIncrease && showToast)
  }

  const updateCart = async (data) => {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (!cartToken) {
      // If no cart, initialise cart with data
      await initialiseCart.mutateAsync(data)
    } else {
      await update.mutateAsync(data)
    }
  }

  const applyDiscount = async (code) => {
    if (!cartToken) {
      // If no cart, create cart then update with discount code
      await initialiseCart.mutateAsync()
    }
    update.mutate({ discount_codes: [{ code }] })
  }

  return {
    addItem,
    addItemWithToast,
    applyDiscount,
    cart,
    initialiseCart,
    isOpen,
    removeItem,
    toggle,
    updateCart,
    updateItemQuantity,
    updateItemQuantityWithToast
  }
}

export { BasketProvider, useBasket }
