/* istanbul ignore file */
import flow from 'lodash/fp/flow'

import {
  removeHiddenProducts,
  generateVariantsKeys,
  formatVariantsPrice,
  formatStockStatus,
  generateKeysFromHandles,
  addProductDiscounts,
  addProductRatings
} from './products-normaliser.utils'

import { TProductNormaliser } from './products-normaliser.types'

export const normalise = flow(
  formatVariantsPrice,
  formatStockStatus,
  generateVariantsKeys,
  generateKeysFromHandles,
  addProductDiscounts,
  addProductRatings
)

export const normaliseProducts: TProductNormaliser = normalise

export const normaliseAndRemoveHiddenProducts: TProductNormaliser = flow(
  removeHiddenProducts,
  normalise
)
