import { z } from 'zod'

export const productTypes = [
  'DISH',
  'BUNDLE',
  'GIFT_CARD',
  'DELIVERY',
  'ADDON',
  'MEAL',
  'TREAT',
  'BREAKFAST',
  'SIDE',
  'MARKETING',
  'SMOOTHIE',
  'PIZZA',
  'TRAYBAKE'
] as const
export const ProductType = z.enum(productTypes)
export type TProductType = z.infer<typeof ProductType>

// TODO: get a full list of all the types and add them to the validator
const nutritionalHighlights = [
  'high_in_protein',
  'protein_source',
  'low_in_sat_fat',
  'low_in_total_fat',
  'low_in_sugar',
  'high_in_iron',
  'source_of_iron',
  '5_of_your_5_a_day',
  '4_of_your_5_a_day',
  'high_in_vit_c',
  'source_of_vitamin_c',
  '3_of_your_5_a_day',
  'source_of_fibre',
  'calcium_source',
  '2_of_your_5_a_day'
] as const
export const NutritionalHighlight = z.enum(nutritionalHighlights)
export type TNutritionalHighlight = z.infer<typeof NutritionalHighlight>
export const NutritionalHighlights = z.array(NutritionalHighlight)
export type TNutritionalHighlights = z.infer<typeof NutritionalHighlights>

export const productStatuses = [
  'PUBLISHED',
  'UNPUBLISHED',
  'REMOVED',
  'HIDDEN'
] as const
export const ProductStatus = z.enum(productStatuses)
export type TProductStatus = z.infer<typeof ProductStatus>

export const variantTypes = ['SUBSCRIPTION', 'ONE_TIME', 'TRIAL'] as const
export const VariantType = z.enum(variantTypes)
export type TVariantType = z.infer<typeof VariantType>

export const variantPortionSizes = ['SINGLE_PORTION', 'DOUBLE_PORTION'] as const
export const VariantPortionSize = z.enum(variantPortionSizes)
export type TVariantPortionSize = z.infer<typeof VariantPortionSize>

export const variantStockStatuses = ['AVAILABLE', 'LOW', 'SOLD_OUT'] as const
export const VariantStockStatus = z.enum(variantStockStatuses)
export type TVariantStockStatus = z.infer<typeof VariantStockStatus>

export const ProductImage = z.object({ src: z.string() })
export type TProductImage = z.infer<typeof ProductImage>

export const ProductVariant = z.object({
  type: z.string(),
  id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  product_id: z.number(),
  shopify_id: z.number(),
  sku_code: z.string(),
  title: z.string(),
  price: z.number(),
  variant_type: VariantType,
  portion_size: VariantPortionSize,
  stock_status: VariantStockStatus,
  unfulfillable: z.boolean().optional(),
  link: z.string()
})

export type TProductVariant = z.infer<typeof ProductVariant>

export const ProductNutritionValues = z.object({
  decimal_places: z.number(),
  label: z.string(),
  unit: z.string(),
  value_100: z.number(),
  value_serving: z.number()
})

export type TProductNutritionValue = z.infer<typeof ProductNutritionValues>

export const Product = z.object({
  type: z.string(),
  id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  shopify_id: z.number().nullable(),
  title: z.string(),
  handle: z.string().nullable(),
  short_description: z.string().nullable(),
  description: z.string().nullable(),
  ingredients_html: z.string(),
  ingredients: z.string(),
  image_url: z.string(),
  images: z.array(ProductImage),
  product_type: ProductType,
  published_at: z.string().nullable(),
  status: ProductStatus,
  tags: z.array(z.string()).nullable(),
  has_gluten: z.boolean(),
  has_nuts: z.boolean(),
  has_soy: z.boolean(),
  unit_weight: z.number().nullable(),
  plant_points: z.number().nullable(),
  is_microwaveable: z.boolean().nullable(),
  is_ovenable: z.boolean().nullable(),
  spice_level: z.number().nullable(),
  nutrition_values: z.array(ProductNutritionValues),
  variants: z.array(ProductVariant),
  nutritional_highlights: z.array(z.string()),
  dish_type: z.array(z.string()),
  cuisine: z.array(z.string()),
  season: z.string().nullable(),
  is_new: z.boolean(),
  link: z.string(),
  trustpilot_skus: z.string().nullable(),
  display_award_badge: z.boolean().optional(),
  resident_chef: z.string().nullable(),
  resident_chef_note: z.string().nullable(),
  microwave_minimum_cook_time_seconds: z.number().nullable(),
  microwave_instructions: z.string().nullable(),
  oven_minimum_cook_time_seconds: z.number().nullable(),
  oven_instructions: z.string().nullable(),
  recommended_cooking_method: z.string().nullable(),
  additional_instructions: z.string().nullable()
})
export type TProduct = z.infer<typeof Product>

export const ProductsResponse = z.object({
  data: z.array(Product),
  status: z.number()
})
export type TProductsResponse = z.infer<typeof ProductsResponse>
