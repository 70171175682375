import { createContext, useState, useContext, ReactNode } from 'react'
import noop from 'lodash/noop'
import { useTrimmedProductsQuery } from '@tofu/shared/hooks/use-trimmed-products-query'

const FiltersContext = createContext({
  filteredProducts: {},
  setFilteredProducts: noop
})
export const useFiltersContext = () => useContext(FiltersContext)

export const FiltersProvider = ({ children }: { children: ReactNode }) => {
  // Default to all products when initially rendered
  const { data: products } = useTrimmedProductsQuery()
  const [filteredProducts, setFilteredProducts] = useState(products)

  return (
    <FiltersContext.Provider value={{ filteredProducts, setFilteredProducts }}>
      {children}
    </FiltersContext.Provider>
  )
}
