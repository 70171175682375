// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { client } from '@tofu/shared/data-access/prismic'
import {
  TGlobalShopSettings,
  TGetGlobalSettingsShop
} from './get-global-shop-settings.types'

export const getGlobalShopSettings =
  async (): Promise<TGetGlobalSettingsShop> => {
    const response = await client.getSingle<TGlobalShopSettings>(
      'global_settings_shop',
      {}
    )

    return {
      default_promo_banner: {
        text: response?.data?.default_promo_banner
      }
    }
  }
