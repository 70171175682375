/**
 * TODO: conver these type to Partial<TProduct> and Partial<TProductVariant>
 * My TS still's aren't good enough to do this tho.
 * Something like this: https://grrr.tech/posts/2021/typescript-partial/#in-conclusion
 */

type DiscountVariant = {
  original_price: number
  discounted_percentage?: number
}

type Discount = {
  variants: {
    DOUBLE_PORTION?: DiscountVariant
    SINGLE_PORTION?: DiscountVariant
  }
}

type TDiscounts = {
  [key: string]: Discount
}

export const DISCOUNTS: TDiscounts = {
  'tofu-chorizo-paella': {
    variants: {
      DOUBLE_PORTION: {
        original_price: 10.25,
        discounted_percentage: 10
      }
    }
  },
  'butternut-lasagne-with-cashew-bechamel': {
    variants: {
      DOUBLE_PORTION: {
        original_price: 10.25,
        discounted_percentage: 10
      }
    }
  }
}

type Rating = {
  rating: number
  totalReviews: number
}

type TRating = {
  [key: string]: Rating
}

export const RATINGS: TRating = {
  'aubergine-tikka-masala-with-pilau-rice': {
    totalReviews: 21,
    rating: 5
  },

  'almond-banana-protein-smoothie': {
    totalReviews: 6,
    rating: 5
  },

  'breakfast-berry-smoothie': {
    totalReviews: 10,
    rating: 4
  },

  'black-bean-power-bowl': {
    totalReviews: 9,
    rating: 4
  },

  'black-bean-udon-noodles': {
    totalReviews: 43,
    rating: 4
  },

  'butternut-lasagne-with-cashew-bechamel': {
    totalReviews: 75,
    rating: 4
  },

  'butternut-squash-sage-risotto': {
    totalReviews: 65,
    rating: 4
  },

  'carbonara-with-crispy-pancetta': {
    totalReviews: 6,
    rating: 4
  },

  'carrot-cake-breakfast-pot': {
    totalReviews: 36,
    rating: 4
  },

  'cauli-korma-with-chickpeas-rice': {
    totalReviews: 22,
    rating: 5
  },

  'chimichurri-portobello-mushroom-bowl': {
    totalReviews: 20,
    rating: 5
  },

  'chocolate-peanut-fondant': {
    totalReviews: 43,
    rating: 4
  },

  'cocoa-banana-breakfast-pot': {
    totalReviews: 43,
    rating: 4
  },

  'coconut-berry-breakfast-pot': {
    totalReviews: 46,
    rating: 4
  },

  'creamy-pasta-with-mushroom-medley': {
    totalReviews: 5,
    rating: 5
  },

  'creamy-potato-dauphinoise': {
    totalReviews: 31,
    rating: 4
  },

  'creamy-rigatoni-with-plant-based-nduja': {
    totalReviews: 79,
    rating: 4
  },

  'crispy-roast-potatoes': {
    totalReviews: 41,
    rating: 5
  },

  'double-green-orecchiette': {
    totalReviews: 24,
    rating: 4
  },

  'firecracker-udon-noodles': {
    totalReviews: 47,
    rating: 4
  },

  'garlic-chilli-greens': {
    totalReviews: 40,
    rating: 4
  },

  'mango-greens-smoothie': {
    totalReviews: 12,
    rating: 4
  },

  'super-spanakopita': {
    totalReviews: 47,
    rating: 4
  },

  'green-protein-buddha-bowl': {
    totalReviews: 65,
    rating: 5
  },

  'harissa-cauliflower-grain-bowl': {
    totalReviews: 28,
    rating: 5
  },

  'mushroom-pancetta-filo-pie': {
    totalReviews: 33,
    rating: 4
  },

  'hoisin-mushroom-udon-noodles': {
    totalReviews: 70,
    rating: 4
  },

  'kale-chickpea-daal': {
    totalReviews: 116,
    rating: 4
  },

  'korean-style-rice-bowl': {
    totalReviews: 42,
    rating: 4
  },

  'kung-pao-cauli': {
    totalReviews: 54,
    rating: 4
  },

  'roasted-veggie-lasagne': {
    totalReviews: 135,
    rating: 4
  },

  'lasagne-noci': {
    totalReviews: 102,
    rating: 5
  },

  'lentil-chutney-chana-bowl': {
    totalReviews: 101,
    rating: 4
  },

  'mango-turmeric-breakfast-pot': {
    totalReviews: 43,
    rating: 4
  },

  'mexican-chilli-with-chipotle-rice': {
    totalReviews: 3,
    rating: 5
  },

  'mild-keralan-butternut-curry': {
    totalReviews: 1,
    rating: 5
  },

  'minty-leeks-peas': {
    totalReviews: 17,
    rating: 4
  },

  'miso-tamari-tofu-bowl': {
    totalReviews: 156,
    rating: 5
  },

  'moroccan-tagine-with-butternut-apricot': {
    totalReviews: 13,
    rating: 5
  },

  'mushroom-leek-filo-tart': {
    totalReviews: 24,
    rating: 4
  },

  'pasta-arrabiata-rich-tomato-sauce': {
    totalReviews: 15,
    rating: 5
  },

  'pb-j-oat-breakfast-pot': {
    totalReviews: 65,
    rating: 4
  },

  'peri-peri-protein-bowl': {
    totalReviews: 26,
    rating: 4
  },

  'protein-bolognese-bowl': {
    totalReviews: 56,
    rating: 4
  },

  'protein-power-buddha-bowl': {
    totalReviews: 225,
    rating: 5
  },

  'lentil-moussaka': {
    totalReviews: 78,
    rating: 4
  },

  'rainbow-falafel-mezze': {
    totalReviews: 64,
    rating: 5
  },

  'rainbow-protein-buddha-bowl': {
    totalReviews: 86,
    rating: 4
  },

  'rigatoni-bolognese-with-almond-parm': {
    totalReviews: 213,
    rating: 4
  },

  'salted-caramel-cheesecake': {
    totalReviews: 40,
    rating: 4
  },

  'truffle-mushroom-orzo': {
    totalReviews: 165,
    rating: 4
  },

  'smoked-aubergine-walnut-ragu': {
    totalReviews: 29,
    rating: 5
  },

  'smoky-corn-spanish-rice-bowl': {
    totalReviews: 59,
    rating: 4
  },

  'smoky-soul-chilli': {
    totalReviews: 117,
    rating: 5
  },

  'tofu-chorizo-paella': {
    totalReviews: 68,
    rating: 4
  },

  'tomato-chorizo-fabada': {
    totalReviews: 36,
    rating: 4
  },

  'spiced-pilau-rice': {
    totalReviews: 18,
    rating: 4
  },

  'spiced-sweet-potato-wedges': {
    totalReviews: 8,
    rating: 5
  },

  'chickpea-aubergine-jalfrezi': {
    totalReviews: 26,
    rating: 4
  },

  'spicy-szechuan-noodles': {
    totalReviews: 66,
    rating: 4
  },

  'spinach-ricotta-lasagne': {
    totalReviews: 39,
    rating: 4
  },

  'sri-lankan-cauli-curry': {
    totalReviews: 1,
    rating: 5
  },

  'sticky-banoffee-pudding': {
    totalReviews: 42,
    rating: 4
  },

  'sticky-teriyaki-udon-noodles': {
    totalReviews: 217,
    rating: 5
  },

  'chilli-tofu-bowl': {
    totalReviews: 55,
    rating: 4
  },

  'tex-mex-protein-bowl': {
    totalReviews: 86,
    rating: 4
  },

  'mushroom-risotto': {
    totalReviews: 94,
    rating: 4
  },

  'nacho-bean-chilli': {
    totalReviews: 58,
    rating: 4
  },

  'tofu-tikka-masala': {
    totalReviews: 77,
    rating: 4
  },

  'tiramisu-cheesecake': {
    totalReviews: 39,
    rating: 4
  },

  'tofu-saag-paneer': {
    totalReviews: 117,
    rating: 4
  },

  'truffle-cauliflower-cheese': {
    totalReviews: 41,
    rating: 4
  },

  'vanilla-blueberry-breakfast-pot': {
    totalReviews: 22,
    rating: 4
  },

  'west-african-peanut-stew': {
    totalReviews: 37,
    rating: 5
  }
}
