var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0a8d0a5a1e60d014041ae26c486ee62ba963270a"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// eslint-disable-next-line import/named
import { init } from '@sentry/nextjs'
import { Dedupe } from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const ignoredKeywords = [
  'DOM Exception 12',
  'QuotaExceededError',
  'Maximum update depth exceeded',
  'CSSStyleSheet',
  'Network Error',
  'Cancelled',
  'cancelled',
  'Request aborted',
  'Network request failed',
  'The internet connection appears to be offline',
  'IndexSizeError',
  'timeout',
  'The network connection was lost',
  'AbortError',
  'window.localStorage.getItem',
  'NS_ERROR_FILE_NO_DEVICE_SPACE',
  '_AutofillCallbackHandler'
]

const pathnamesToSample = new Set(['/', '/shop'])

const sentryOptions = {
  allowUrls: ['allplants.com'],
  attachStacktrace: true,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.VERCEL_ENV,
  ignoreErrors: ignoredKeywords,
  integrations: [new Dedupe()],
  maxBreadcrumbs: 50,
  release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1
}

if (process.env.ENVIRONMENT !== 'development') {
  init({
    dsn:
      SENTRY_DSN ||
      'https://1bae80910abd442cb731aefa06d069ab@o189517.ingest.sentry.io/6230741',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampler: ({ location }) => {
      if (pathnamesToSample.has(location.pathname)) {
        return 0.5
      }

      return 0.05
    },
    ...sentryOptions
  })
}
