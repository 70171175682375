import equals from 'lodash/fp/equals'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import includes from 'lodash/fp/includes'
import negate from 'lodash/fp/negate'
import overEvery from 'lodash/fp/overEvery'
import overSome from 'lodash/fp/overSome'
import find from 'lodash/fp/find'

const getSeason = get('season')
const getDishType = get('dish_type')
const getProductType = get('product_type')

const includesGrainBowls = includes('grain_bowls')
const includesNoodles = includes('noodles')
const includesPastas = includes('pastas')
const includesRicesAndRisottos = includes('rices_and_risottos')
const includesCurries = includes('curries')
const includesStews = includes('stews')
const includesPiesAndBakes = includes('pies_and_bakes')
const includesDessertPots = includes('dessert_pots')
const includesAutumn = includes('autumn')
const includesWinter = includes('winter')
const includesSpring = includes('spring')
const includesSummer = includes('summer')

const equalsBreakfast = equals('BREAKFAST')
const equalsTreat = equals('TREAT')
const equalsTraybake = equals('TRAYBAKE')
const equalsSmoothie = equals('SMOOTHIE')
const equalsSide = equals('SIDE')
const equalsMeal = equals('MEAL')
const equalsPizza = equals('PIZZA')

export const isBuddhaBowl = flow(getDishType, includesGrainBowls)
export const isNoodleBowl = flow(getDishType, includesNoodles)
export const isPasta = flow(getDishType, includesPastas)
export const isRiceAndRisotto = flow(getDishType, includesRicesAndRisottos)
export const isCurry = flow(getDishType, includesCurries)
export const isStew = flow(getDishType, includesStews)
export const isFiloPie = flow(getDishType, includesPiesAndBakes)
export const isDessertPots = flow(getDishType, includesDessertPots)
export const isBreakfast = flow(getProductType, equalsBreakfast)
export const isTreat = flow(getProductType, equalsTreat)
export const isTrayBake = flow(getProductType, equalsTraybake)
export const isSmoothie = flow(getProductType, equalsSmoothie)

// Seasonal
export const isAutumn = flow(getSeason, includesAutumn)
export const isWinter = flow(getSeason, includesWinter)
export const isSpring = flow(getSeason, includesSpring)
export const isSummer = flow(getSeason, includesSummer)

export const isSide = flow(getProductType, equalsSide)
export const isMeal = flow(getProductType, equalsMeal)
export const isPizza = flow(getProductType, equalsPizza)

export const isNonPieSide = overEvery([isSide, negate(isFiloPie)])

export const isPastaOrRisotto = overSome([isPasta, isRiceAndRisotto])
export const isDessert = overSome([isTreat, isTrayBake, isDessertPots])
export const isExtra = overSome([
  isTrayBake,
  isTreat,
  isSmoothie,
  isSide,
  isBreakfast
])

export const filterBuddhaBowls = filter(isBuddhaBowl)
export const filterNoodleBowls = filter(isNoodleBowl)
export const filterPastaOrRisottos = filter(isPastaOrRisotto)
export const filterCurries = filter(isCurry)
export const filterStews = filter(isStew)
export const filterFiloPies = filter(isFiloPie)
export const filterBreakfasts = filter(isBreakfast)
export const filterDesserts = filter(isDessert)
export const filterSmoothies = filter(isSmoothie)
export const filterSides = filter(isSide)
export const filterMeals = filter(isMeal)
export const filterPizza = filter(isPizza)
export const filterAutumnMenu = filter(isAutumn)
export const filterWinterMenu = filter(isWinter)
export const filterSpringMenu = filter(isSpring)
export const filterSummerMenu = filter(isSummer)
export const filterExtra = filter(isExtra)

export const filterNonPieSide = filter(isNonPieSide)

export const findSides = find(isSide)
export const findMeals = find(isMeal)
export const findDesserts = find(isDessert)
export const findSmoothies = find(isSmoothie)
export const findBreakfasts = find(isBreakfast)
